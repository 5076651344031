import React from "react";
import classnames from "classnames";
import Select from "react-select";

import {
  Alert,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  CustomInput,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

import DemoFooter from "components/Footers/DemoFooter.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import TagsInput from "components/TagsInput/TagsInput.js";
import VsstaHomeNavbar from "components/Navbars/VsstaHomeNavbar";
import TechSupportForm from "components/Forms/TechSupportForm";

export default function AccountSettings() {
  const [profileTabs, setProfileTabs] = React.useState(1);
  const [selectedQuestion, setSelectedQuestion] = React.useState("");
  const [tags, setTags] = React.useState([
    "HTML5",
    "CSS3",
    "JavaScript",
    "React",
  ]);
  const wrapper = React.useRef(null);
  function handleChange(x) {
    setSelectedQuestion(x.answer);
  }
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("account-settings");
    return function cleanup() {
      document.body.classList.remove("account-settings");
    };
  }, []);
  return (
    <>
      <VsstaHomeNavbar />
      <div style={{ margin: "150px" }}></div>
      <div className="wrapper" ref={wrapper}>
        <div className="section">
          <Container>
            <Row>
              <Col md="3">
                <div className="section">
                  {/* User Information */}
                  <section className="text-center">
                    <h3 className="title">Vssta Support Team</h3>
                  </section>
                  {/* User Information */}
                  {/* Profile Sidebar */}
                  <section>
                    <br />
                    <Nav className="flex-column nav-tabs-info" role="tablist">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: profileTabs === 1,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setProfileTabs(1);
                          }}
                          href=""
                        >
                          <i className="tim-icons icon-single-02" /> Tech
                          Support Ticket
                        </NavLink>
                      </NavItem>
                      <hr className="line-info" />
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: profileTabs === 3,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setProfileTabs(3);
                          }}
                          href=""
                        >
                          <i className="tim-icons icon-pencil" />
                          Frequently Asked Questions
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </section>
                </div>
              </Col>
              <Col className="ml-auto" md="8">
                <div className="section">
                  <TabContent activeTab={"profile" + profileTabs}>
                    <TabPane tabId="profile1">
                      <TechSupportForm />
                    </TabPane>
                    <TabPane tabId="profile3">
                      <div className="g-pos-rel h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-30--md">
                        <header>
                          <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            Frequently Asked Questions
                          </h2>
                        </header>
                        <hr className="line-info" />
                        <Form>
                          <Row>
                            <Col md="6">
                              <label>Asked Questions</label>
                              <FormGroup>
                                <Select
                                  className="react-select react-select-info"
                                  classNamePrefix="react-select"
                                  placeholder="Question"
                                  onChange={handleChange}
                                  options={[
                                    {
                                      value: "1",
                                      label: "Your Question",
                                      isDisabled: true,
                                      answer: "",
                                    },
                                    {
                                      value: "2",
                                      label: "How do I log into the portal",
                                      answer:
                                        "You will first need to have registered and already have an active login. Once registration and login have been completed you can then access the portal by clicking the Login to Portal Button at the top of the navbar ",
                                    },
                                    {
                                      value: "3",
                                      label: "How do I get activation code",
                                      answer:
                                        "To receive an activation code you will have to already completed the following. \
                                        Scheduled a Call with Vssta Sales Team. \
                                        Payment Credentials have been provied to Vssta Sales Team. \
                                        Shipping Information have been provided to Vssta Sales Team. \
                                        \
                                        When all the conditions have been met you will recieve an email containing your activation code.",
                                    },
                                    {
                                      value: "4",
                                      label: "How do I register",
                                      answer:
                                        "To register all you need to do is take the activation code and end click the Register button in the top of the navbar and fill out the shop credentials. You will then take those credentials and click the login to portal where you will now have access to the Vssta Portal Dashboard",
                                    },
                                    {
                                      value: "5",
                                      label:
                                        "What is the benefits for choosing Vssta",
                                      answer:
                                        "Vssta's award winning profit sharing model allows the \
                                        body shop to keep more money in house. ALso first month \
                                        of service is free to new registered users to allow a \
                                        return on the intial investment to cover scanner costs.\
                                        \
                                        \
                                        VSSTA wants to use the technology to change the collision\
                                        repair industry. We believe that Pre / Post scans are just\
                                        the beginning. With our technology and integration features\
                                        it will be easier to make the shift into repairing vehicles\
                                        properly and getting paid for the repair. VSSTA is bridging\
                                        the gap between our tools, management systems, technology\
                                        and procedures. The world is connected help us connect the\
                                        Collision Repair Industry.",
                                    },
                                  ]}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <br />
                          <br />
                          <header>
                            <h2 className="text-uppercase">Answers</h2>
                          </header>
                          <div>{selectedQuestion}</div>
                          <hr className="line-info" />
                        </Form>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <div style={{ margin: "370px" }}></div> */}
      </div>
    </>
  );
}
