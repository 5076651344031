import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Link,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import VsstaHomeNavbar from "components/Navbars/VsstaHomeNavbar";
import ContactUsForm from "components/Forms/ContactUsForm.js";

export default function Success(props) {
  console.log(props.location.state);
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("contact-page");
    return function cleanup() {
      document.body.classList.remove("contact-page");
    };
  }, []);
  return (
    <>
      <VsstaHomeNavbar />
      <div className="wrapper" ref={wrapper}>
        <div className="page-header contactus-2" style={{ margin: "100px" }}>
          <Container>
            <Row>
              {props.location.state ? (
                <Col className="text-center" md="12">
                  <h1 className="title">
                    {props.location.state.message.text1}
                  </h1>
                  <h2>{props.location.state.message.text2}</h2>
                  <Button className="btn-link" color="success" href="/">
                    Go back to main page.
                  </Button>
                </Col>
              ) : (
                <Col className="text-center" md="12">
                  <h1 className="title">
                   Nothing here.
                  </h1>
                  <Button className="btn-link" color="success" href="/">
                    Go back to main page.
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
