import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import VsstaHomeNavbar from "components/Navbars/VsstaHomeNavbar";
import ContactUsForm from "components/Forms/ContactUsForm.js";

export default function ContactUs() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("contact-page");
    return function cleanup() {
      document.body.classList.remove("contact-page");
    };
  }, []);
  return (
    <>
      <VsstaHomeNavbar />
      <div className="wrapper" ref={wrapper}>
        <div className="page-header header-filter contactus-3">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/vssta_test.jpg").default + ")",
            }}
          />
          <Container>
            <Row>
              <Col className="text-center" md="12">
                <h1 className="title">Got a question?</h1>
                <h3>We'd like to talk more about what you need</h3>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="main">
          <Container fluid>
            <Row className="infos mb-5">
              <Col lg="3">
                <div className="info info-hover">
                  <div className="icon icon-primary">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={
                        require("assets/img/feature-blob/primary.png").default
                      }
                    />
                    <i className="tim-icons icon-square-pin" />
                  </div>
                  <h4 className="info-title">Address</h4>
                  <p className="description">
                    502 W Montgomery #535, Willis Texas 77378
                  </p>
                </div>
              </Col>
              <Col lg="3">
                <div className="info info-hover">
                  <div className="icon icon-info">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={require("assets/img/feature-blob/info.png").default}
                    />
                    <i className="tim-icons icon-email-85" />
                  </div>
                  <h4 className="info-title">Email</h4>
                  <p className="description">info@vssta.com</p>
                </div>
              </Col>
              <Col lg="3">
                <div className="info info-hover">
                  <div className="icon icon-warning">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={
                        require("assets/img/feature-blob/warning.png").default
                      }
                    />
                    <i className="tim-icons icon-mobile" />
                  </div>
                  <h4 className="info-title">Schedule A Call</h4>
                  <Button
                    className="btn-round"
                    color="light"
                    href="https://calendly.com/bobby-142"
                  >
                    Click Here
                  </Button>
                </div>
              </Col>
              <Col lg="3">
                <div className="info info-hover">
                  <div className="icon icon-success">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={
                        require("assets/img/feature-blob/success.png").default
                      }
                    />
                    <i className="tim-icons icon-single-02" />
                  </div>
                  <h4 className="info-title">Contact</h4>
                  <p className="description">Bobby Beason</p>
                </div>
              </Col>
            </Row>
            <Row className="mt-5 mb-4 pt-5">
              <Col className="ml-auto mr-auto text-center mt-5" md="8">
                <Badge color="info">Leave a message</Badge>
                <h1 className="title">
                  Tell us more about <b>yourself</b>
                </h1>
                <h4 className="desc">
                  Whether you have questions or you would just like to say
                  hello, contact us.
                </h4>
              </Col>
            </Row>
            <ContactUsForm />
          </Container>
        </div>
      </div>
    </>
  );
}
