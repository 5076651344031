import React, { useState } from "react";
import { useForm } from "react-hook-form";
// nodejs library that concatenates classes
import classnames from "classnames";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Alert,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  CustomInput,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";

// core components
import VsstaHomeNavbar from "components/Navbars/VsstaHomeNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import TagsInput from "components/TagsInput/TagsInput.js";

import axios from "../../utils/Axios";

export default function SignUpTicket() {
  const { register, handleSubmit, errors } = useForm();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [shopEmail, setShopEmail] = useState("");
  const [userList, setUserList] = useState({ values: [] });
  const [validationCode, setValidationCode] = useState("");
  const [validationCodeError, setValidationCodeError] = useState("");
  const [validationCodeValid, setValidationCodeValid] = useState(false);
  const [scannerSerial, setScannerSerial] = useState("");
  const [scannerSerialError, setScannerSerialError] = useState("");
  const [scannerSerialValid, setScannerSerialValid] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("account-settings");
    return function cleanup() {
      document.body.classList.remove("account-settings");
    };
  }, []);

  function checkValidationCode() {
    axios
      .post("/activation/register_company_shop_users", {
        code: validationCode,
      })
      .then((res) => {
        setValidationCodeValid(true);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          setValidationCodeError("Error: Activation code length is incorrect");
        }

        if (error.response.status === 403) {
          setValidationCodeError(
            "Invalid Activation code entered, please try again"
          );
        }

        setValidationCodeValid(false);
      });
  }
  function checkScannerSerial() {
    if (scannerSerial.length != 12) {
      setScannerSerialError("Scanner Serial Number Length Is Incorrect");
    }

    setScannerSerialValid(false);
  }

  function onSubmit(data) {
    var backendData = data;
    backendData.users = userList.values;
    setLoadingSpinner(true);
    http: axios
      .post("/activation/register_company_shop_users", backendData)
      .then((res) => {
        document.getElementById("form").reset();
        setShopEmail("");
        setUserList({ values: [] });
        setScannerSerial("");
        setValidationCode("");
        setValidationCodeError("");
        setValidationCodeValid(false);
        setFormSuccess(true);
        window.scrollTo(0, 0);
        setLoadingSpinner(false);
      })
      .catch((error) => {
        console.log(error);
        setFormSuccess(false);
        setLoadingSpinner(false);
      });
  }

  function addClick() {
    setUserList((prevState) => ({
      values: [
        ...prevState.values,
        { firstName: "", lastName: "", email: "", jobTitle: "Estimator" },
      ],
    }));
  }

  function removeClick(i) {
    let values = [...userList.values];
    values.splice(i, 1);
    setUserList({ values });
  }

  function onTypeActivationCode(e) {
    if (validationCodeError) setValidationCodeError("");

    setValidationCode(e.target.value);
  }
  function onTypeScannerSerial(e) {
    if (scannerSerialError) setScannerSerialError("");

    setScannerSerial(e.target.value);
  }

  function handleUserFirstNameChange(i, event) {
    let values = [...userList.values];
    values[i].firstName = event.target.value;
    setUserList({ values });
  }

  function handleUserLastNameChange(i, event) {
    let values = [...userList.values];
    values[i].lastName = event.target.value;
    setUserList({ values });
  }

  function handleUserEmailChange(i, event) {
    let values = [...userList.values];
    values[i].email = event.target.value;
    setUserList({ values });
  }
  function handleUserJobTitleChange(i, event) {
    let values = [...userList.values];
    values[i].jobTitle = event.target.value;
    setUserList({ values });
  }

  function createUserTable() {
    return userList.values.map((currentUser, i) => (
      <div>
        <h4>User number {i + 1}</h4>
        <div>
          <Row>
            <Col className="align-self-center" md="3">
              <label className="labels" htmlFor="#first_name">
                First Name
              </label>
            </Col>
            <Col className="align-self-center" md="9">
              <FormGroup>
                <Input
                  innerRef={register({ required: true })}
                  placeholder="Charlie"
                  id="first_name"
                  name="first_name"
                  required={true}
                  type="text"
                  value={currentUser.firstName || ""}
                  onChange={handleUserFirstNameChange.bind(
                    currentUser.firstName,
                    i
                  )}
                />
                {errors.first_name && (
                  <Alert color="danger">This field is required</Alert>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="align-self-center" md="3">
              <label className="labels" htmlFor="#last_name">
                Last Name
              </label>
            </Col>
            <Col className="align-self-center" md="9">
              <FormGroup>
                <Input
                  innerRef={register({ required: true })}
                  placeholder="Bailey"
                  id="last_name"
                  name="last_name"
                  required={true}
                  type="text"
                  value={currentUser.lastName || ""}
                  onChange={handleUserLastNameChange.bind(
                    currentUser.lastName,
                    i
                  )}
                />
                {errors.last_name && (
                  <Alert color="danger">This field is required</Alert>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="align-self-center" md="3">
              <label className="labels" htmlFor="#email">
                Email
              </label>
            </Col>
            <Col className="align-self-center" md="9">
              <FormGroup>
                <Input
                  innerRef={register({ required: true })}
                  placeholder="charlie.bailey@example.com"
                  id="email"
                  name="email"
                  required={true}
                  type="email"
                  value={currentUser.email || ""}
                  onChange={handleUserEmailChange.bind(currentUser.email, i)}
                />
                {errors.email && (
                  <Alert color="danger">This field is required</Alert>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="align-self-center" md="3">
              <label className="labels" htmlFor="#last_name">
                Job Title
              </label>
            </Col>
            <Col className="align-self-center" md="9">
              <FormGroup>
                <Input
                  innerRef={register({ required: true })}
                  placeholder="Estimator"
                  id="jobTitle"
                  name="jobTitle"
                  required={true}
                  type="select"
                  value={currentUser.jobTitle || "Estimator"}
                  className="react-select react-select-info"
                  classNamePrefix="react-select"
                  onChange={handleUserJobTitleChange.bind(
                    currentUser.jobTitle,
                    i
                  )}
                >
                  <option value="Estimator">Estimator</option>
                  <option value="Shop Manager">Shop Manager</option>
                  <option value="Shop Regional Manager">
                    Shop Regional Manager
                  </option>
                  <option value="Company Owner">Company Owner</option>
                  <option value="Technician">Technician</option>
                </Input>
                {errors.last_name && (
                  <Alert color="danger">This field is required</Alert>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Button color="warning" type="button" onClick={removeClick.bind(i)}>
            Remove User
          </Button>
        </div>
        <br></br>
      </div>
    ));
  }

  return (
    <>
      <VsstaHomeNavbar />
      <div className="wrapper" ref={wrapper}>
        <div style={{ margin: "150px" }}></div>
        <div className="section">
          <Container>
            <div>
              {formSuccess ? (
                <Alert color="success">
                  Thank You For Signing Up With Vssta! You Will Recieve an Email
                  Shortly Containing Log In Information.{" "}
                </Alert>
              ) : null}
            </div>
            <Form onSubmit={handleSubmit(onSubmit)} id="form">
              <Row style={{ paddingLeft: "100px", paddingRight: "100px" }}>
                <Col className="ml-auto" md="12">
                  <div className="section">
                    <header>
                      <h2 className="text-uppercase">Activation Code</h2>
                    </header>
                    <hr className="line-info" />
                    <br />
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#code">
                          Code
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="Activation Code"
                            id="code"
                            name="code"
                            value={validationCode}
                            onChange={onTypeActivationCode}
                            required={true}
                            onBlur={checkValidationCode}
                            type="text"
                          />
                          {validationCodeValid && (
                            <Alert color="success">
                              Activation code is valid!
                            </Alert>
                          )}
                          {errors.code && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                          {validationCodeError && (
                            <Alert color="danger">{validationCodeError}</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <p style={{ margin: "40px" }}></p>
                    <header>
                      <h2 className="text-uppercase">Payables Information</h2>
                    </header>
                    <hr className="line-info" />
                    <br />
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#contactname">
                          Contact Name
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="Name..."
                            id="contactname"
                            name="contactname"
                            required={true}
                            type="text"
                          />
                          {errors.username && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#contactphone">
                          Contact Phone
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="740-548-000"
                            id="contactphone"
                            name="contactphone"
                            required={true}
                            type="text"
                          />
                          {errors.username && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#contactemail">
                          Email
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="charlie.bailey@example.com"
                            id="contactemail"
                            name="contactemail"
                            required={true}
                            type="text"
                          />
                          {errors.username && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <p style={{ margin: "40px" }}></p>
                    <header>
                      <h2 className="text-uppercase">Company information</h2>
                    </header>
                    <hr className="line-info" />
                    <br />
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#companyname">
                          Company Name
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="Company Name..."
                            id="companyname"
                            name="companyname"
                            required={true}
                            type="text"
                          />
                          {errors.username && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <p style={{ margin: "40px" }}></p>
                    <header>
                      <h2 className="text-uppercase">Shop information</h2>
                    </header>
                    <hr className="line-info" />
                    <br />
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#shop_name">
                          Shop Name
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="Mikes Collision Center"
                            id="shop_name"
                            name="shop_name"
                            required={true}
                            type="text"
                          />
                          {errors.shop_name && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#number">
                          Number
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="380"
                            id="number"
                            name="number"
                            required={true}
                            type="text"
                          />
                          {errors.number && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#street">
                          Street Address
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="South Main Street"
                            id="street"
                            name="street"
                            required={true}
                            type="text"
                          />
                          {errors["street"] && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#city">
                          City
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="Houston"
                            id="city"
                            name="city"
                            required={true}
                            type="text"
                          />
                          {errors.city && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#state">
                          State
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="Texas"
                            id="state"
                            name="state"
                            required={true}
                            type="text"
                          />
                          {errors.state && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#post_code">
                          Post Code
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="43318"
                            id="post_code"
                            name="post_code"
                            required={true}
                            type="text"
                          />
                          {errors.post_code && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#country">
                          Country
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="United States"
                            id="country"
                            name="country"
                            required={true}
                            type="text"
                          />
                          {errors.country && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#wifi_user">
                          Wifi User Name
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="Wifi Username"
                            id="wifi_user"
                            name="wifi_user"
                            required={true}
                            type="text"
                          />
                          {errors.wifi_user && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#wifi_pass">
                          Wifi Password
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            placeholder="Wifi Password"
                            id="wifi_pass"
                            innerRef={register({ required: true })}
                            name="wifi_pass"
                            required={true}
                            type="text"
                          />
                          {errors.wifi_pass && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#scanner_serial">
                          Scanner Serial Number
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            placeholder="989770001***"
                            id="scanner_serial"
                            innerRef={register({ required: true })}
                            name="scanner_serial"
                            required={true}
                            type="text"
                            value={scannerSerial}
                            onChange={onTypeScannerSerial}
                            onBlur={checkScannerSerial}
                          />
                          {scannerSerialValid && (
                            <Alert color="success">
                              Scanner Serial Is Good!
                            </Alert>
                          )}
                          {errors.code && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                          {scannerSerialError && (
                            <Alert color="danger">{scannerSerialError}</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#telephone">
                          Phone Number
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            placeholder="740-548-000"
                            id="telephone"
                            innerRef={register({ required: true })}
                            name="telephone"
                            required={true}
                            type="text"
                          />
                          {errors.telephone && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#shop_email">
                          Shop Email
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            placeholder="charlie.bailey@example.com"
                            id="shop_email"
                            innerRef={register({ required: true })}
                            name="shop_email"
                            required={true}
                            value={shopEmail}
                            onChange={(e) => setShopEmail(e.target.value)}
                            type="email"
                          />
                          {errors.shop_email && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels">Management System</label>
                      </Col>
                      <Col className="align-self-center" md="4">
                        <FormGroup>
                          <Input
                            type="select"
                            innerRef={register({ required: true })}
                            id="managementSystem"
                            name="managementSystem"
                            required={true}
                            className="react-select react-select-info"
                            classNamePrefix="react-select"
                            placeholder="Management System"
                          >
                            <option value="None">None</option>
                            <option value="Rome">Rome</option>
                            <option value="CCC One">CCC One</option>
                            <option value="CC3">CC3</option>
                            <option value="Mitchell">Mitchell</option>
                            <option value="Nexysis">Nexysis</option>
                          </Input>
                          {errors.managementSystem && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <p style={{ margin: "40px" }}></p>
                    <header>
                      <h2 className="text-uppercase">User Information</h2>
                    </header>
                    <hr className="line-info" />
                    <br></br>
                    {createUserTable()}
                    <Button
                      color="info"
                      type="button"
                      value="Add user"
                      onClick={addClick.bind(userList)}
                    >
                      Add user
                    </Button>
                    <Row className="mt-4">
                      <Col md="6">
                        <Button
                          color="info"
                          type="submit"
                          onClick={handleSubmit(onSubmit)}
                        >
                          Submit Ticket
                        </Button>
                        <Button
                          className="btn-simple ml-1"
                          color="info"
                          type="button"
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                    <div>
                      {loadingSpinner ? (
                        <Spinner
                          animation="border"
                          color="success"
                          style={{ marginTop: "10px", textAlign: "center" }}
                        />
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </div>
      <div style={{ margin: "250px" }}></div>
    </>
  );
}
