import React from "react";
import { Line } from "react-chartjs-2";
import VsstaHomeNavbar from "components/Navbars/VsstaHomeNavbar";
import VideoLoader from "./VideoLoader";
import SCANNER_SILVER from "assets/img/vssta_bronze.png"
import SCANNER_GOLD from "assets/img/vssta_gold.png"
import SCANNER_DIAMOND from "assets/img/vssta_diamond.png"
import DASHBOARD_IMG from "assets/img/dashboard_vssta.jpg"

// import VsstaVideo from "..../assets/VsstaVideo";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import Calculator from "./Calculator.js";

import { chartExample1 } from "variables/charts.js";
import MyForm from "components/Forms/ContactUsForm";

export default function HomePage() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  }, []);
  return (
    <>
      <VsstaHomeNavbar />
      <div className="wrapper" ref={wrapper}>
        <div className="page-header">
          <img
            alt="..."
            className="path"
            src={require("assets/img/blob.png").default}
          />
          <img
            alt="..."
            className="path2"
            src={require("assets/img/path2.png").default}
          />
          <img
            alt="..."
            className="shapes triangle"
            src={require("assets/img/triunghiuri.png").default}
          />
          <img
            alt="..."
            className="shapes wave"
            src={require("assets/img/waves.png").default}
          />
          <img
            alt="..."
            className="shapes squares"
            src={require("assets/img/patrat.png").default}
          />
          <img
            alt="..."
            className="shapes circle"
            src={require("assets/img/cercuri.png").default}
          />
          <Container>
            <VideoLoader />
            <Row className="row-grid justify-content-between align-items-center text-left">
              <Col lg="6" md="6">
                <h1 className="text-white display-1">
                  Monthly* <br />
                  <span className="text-white">Guarantee</span>
                </h1>
                <p className="text-white mb-3 h3">
                  Let VSSTA guide you down the path to increased profitability.
                </p>
                <p>
                  <Button
                    className="btn"
                    color="light"
                    href="https://calendly.com/bobby-142"
                  >
                    Book A Call
                  </Button>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="section section-lg">
          <section className="section">
            <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png").default}
            />
            <Container>
              <Row className="row-grid justify-content-between">
                <Col md="6">
                  <Calculator />
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <h1 className="display-2">
                      VSSTA <br />
                      Calculator
                    </h1>
                    <p className="text-white h3">
                      At VSSTA our business is based on adding value, lowering
                      liability, and increasing shops profitability.
                    </p>
                    <p className="text-white h3">
                      {" "}
                      While scanning is a great way to do these things, it is
                      also the first step in the repair process. Our remote scan
                      process is just the beginning. Utilizing VSSTA we can
                      ensure that you don't miss essential scans, and inform
                      your shop of required ADAS and safety calibrations.
                    </p>
                    <p className="text-white h3">
                      {" "}
                      This is how VSSTA is able to add additional profit to your
                      repair shop and protect your shops liability.
                    </p>
                    <br />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </section>
        <section className="section section-lg">
          <img
            alt="..."
            className="path"
            src={require("assets/img/path4.png").default}
          />
          <img
            alt="..."
            className="path2"
            src={require("assets/img/path5.png").default}
          />
          <img
            alt="..."
            className="path3"
            src={require("assets/img/path2.png").default}
          />
          <Container>
            <Row className="h3 justify-content-center">
              <Col lg="12">
                <h1 className="text-center display-2">Your best benefit</h1>
                <Row className="row-grid justify-content-center">
                  <Col lg="4">
                    <div className="info">
                      <div className="icon icon-primary">
                        <i className="tim-icons icon-money-coins" />
                      </div>
                      <h2 className="info-title">Profit Sharing</h2>
                      <hr className="line-primary" />
                      <p className="text-white">
                        VSSTA’s award winning profit sharing model allows the
                        shop to keep more money in house. Real time alerts help
                        to make sure you don’t miss essential scans. OEM repair
                        data helps to show when ADAS calibrations and safety
                        inspections are needed.
                      </p>
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                        href="/contact-us"
                      >
                        Learn More
                      </Button>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="info">
                      <div className="icon icon-warning">
                        <i className="tim-icons icon-chart-pie-36" />
                      </div>
                      <h2 className="info-title">High Incomes</h2>
                      <hr className="line-warning" />
                      <p className="text-white">
                        VSSTA is confident in our software and service that
                        we offer a VSSTA guarantee. Follow our scan process,
                        receive OEM information at the right time and increase
                        profits. Our guarantee is that simple. No contract or
                        commitments.
                      </p>
                      <Button
                        className="btn-round"
                        color="danger"
                        type="submit"
                        href="/contact-us"
                      >
                        Learn More
                      </Button>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="info">
                      <div className="icon icon-success">
                        <i className="tim-icons icon-single-02" />
                      </div>
                      <h2 className="info-title">Customer Support</h2>
                      <hr className="line-success" />
                      <p className="text-white">
                        VSSTA software allows the shop to click one button for
                        remote support. On average our scan times are 7 minutes.
                        Support is available for pre, post, diagnostic and ADAS
                        scans and calibrations.
                      </p>
                      <Button
                        className="btn-round"
                        color="success"
                        type="submit"
                        href="/contact-us"
                      >
                        Request Free Demo
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg section-safe">
          <img
            alt="..."
            className="path"
            src={require("assets/img/path5.png").default}
          />
          <Container>
            <Row className="row-grid justify-content-between">
              <Col md="5">
                <img
                  alt="..."
                  className="img-fluid floating"
                  src={DASHBOARD_IMG}
                />
                <div className="pb-5">
                  <Card className="card-stats bg-danger">
                    <CardBody>
                      <div className="justify-content-center">
                        <div className="numbers">
                          <CardTitle tag="p">No</CardTitle>
                          <p className="card-category text-white">Contract's</p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="card-stats bg-info">
                    <CardBody>
                      <div className="justify-content-center">
                        <div className="numbers">
                          <CardTitle tag="p">100%</CardTitle>
                          <p className="card-category text-white">
                            Satisfaction Gauranteed
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="card-stats bg-default">
                    <CardBody>
                      <div className="justify-content-center ">
                        <div className="numbers">
                          <CardTitle tag="p"></CardTitle>
                          <p className="card-category text-white">
                            Monthly Income
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <ul className="list-unstyled mt-5">
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div className="icon icon-success mb-2">
                        <i className="tim-icons icon-paper" />
                      </div>
                      <div className="ml-3">
                        <h6>Protect/Prevent liability with technology</h6>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div className="icon icon-success mb-2">
                        <i className="tim-icons icon-tap-02" />
                      </div>
                      <div className="ml-3">
                        <h6>Ease the repair process through automation</h6>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div className="icon icon-success mb-2">
                        <i className="tim-icons icon-single-02" />
                      </div>
                      <div className="ml-3">
                        <h6>Repair procedure assistance</h6>
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>
              <Col md="6">
                <div className="px-md-6">
                  <hr className="line-success" />
                  <h1 className="display-2">VSSTA Vision</h1>
                  <p className="text-white h3">
                    VSSTA wants to use the technology to change the collision
                    repair industry. We believe that Pre / Post scans are just
                    the beginning. With our technology and integration features
                    it will be easier to make the shift into repairing vehicles
                    properly and getting paid for the repair. VSSTA is bridging
                    the gap between our tools, management systems, technology
                    and procedures. The world is connected help us connect the
                    Collision Repair Industry.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section section-lg section-coins ">
          <img
            alt="..."
            className="path"
            src={require("assets/img/path3.png").default}
          />
          <Container>
            <Row>
              <Col md="4">
                <hr className="line-info" />
                <h1>
                  Choose the package{" "}
                  <span className="text-info">that fits your needs</span>
                </h1>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      style={{ width: 400 }}
                      alt="..."
                      className="img-center img-fluid"
                      src={SCANNER_SILVER}
                    />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase">Remote Tech</h4>
                        <span>Plan</span>
                        <hr className="line-info" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                        <ListGroupItem>~Scans</ListGroupItem>
                        <ListGroupItem>~Calibrations</ListGroupItem>
                        <ListGroupItem>~Programming</ListGroupItem>
                        <p className="text-white">
                          VSSTA remote technician software allows our team to
                          remote assist. Just pay as you go.
                        </p>
                      </ListGroup>
                    </Row>
                  </CardBody>

                  <CardFooter className="text-center"></CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      style={{ width: 400 }}
                      alt="..."
                      className="img-center img-fluid"
                      src={SCANNER_GOLD}
                    />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase">Essential Package</h4>
                        <span>Plan</span>
                        <hr className="line-success" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                        <ListGroupItem>~VSSTA Remote Scanner</ListGroupItem>
                        <ListGroupItem>~Free vehicle updates</ListGroupItem>
                        <ListGroupItem>
                          ~Online portal reporting and invoicing
                        </ListGroupItem>
                        <ListGroupItem>
                          ~Invoicing and DTC data emailed instantly
                        </ListGroupItem>
                        <ListGroupItem>
                          ~Live dashboard with real time data
                        </ListGroupItem>
                        <ListGroupItem>~Missed scan alerts</ListGroupItem>
                        <ListGroupItem>~One click remote support</ListGroupItem>
                        <ListGroupItem>
                          ~Multi-shop management views
                        </ListGroupItem>
                        <ListGroupItem>~Data export</ListGroupItem>
                        <ListGroupItem>~Equipment discount</ListGroupItem>
                        <ListGroupItem>~No contract</ListGroupItem>
                      </ListGroup>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center"></CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      style={{ width: 400 }}
                      alt="..."
                      className="img-center img-fluid"
                      src={SCANNER_DIAMOND}
                    />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase">Ultimate Package</h4>
                        <span>Plan</span>
                        <hr className="line-primary" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                        <ListGroupItem>~VSSTA Remote Scanner</ListGroupItem>
                        <ListGroupItem>~Free vehicle updates</ListGroupItem>
                        <ListGroupItem>
                          ~Online portal reporting and invoicing
                        </ListGroupItem>
                        <ListGroupItem>
                          ~Invoicing and DTC data emailed instantly
                        </ListGroupItem>
                        <ListGroupItem>
                          ~Live dashboard with real time data
                        </ListGroupItem>
                        <ListGroupItem>~Missed scan alerts</ListGroupItem>
                        <ListGroupItem>~One click remote support</ListGroupItem>
                        <ListGroupItem>~ADAS Assistance</ListGroupItem>
                        <ListGroupItem>
                          ~Multi-shop management views
                        </ListGroupItem>
                        <ListGroupItem>~Data export</ListGroupItem>
                        <ListGroupItem>
                          ~Management system integration
                        </ListGroupItem>
                        <ListGroupItem>~Equipment discount</ListGroupItem>
                        <ListGroupItem>~No contract</ListGroupItem>
                      </ListGroup>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center"></CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
}
