import React from "react";
import classnames from "classnames";
import {
  Badge,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

export default class ContactUsForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
      firstNameFocus: "",
      lastNameFocus: "",
      emailFocus: "",
    };
  }
  render() {
    return (
      <Row>
        <Col className="mx-auto" md="10">
          <Form
            className="p-3"
            id="contact-form-1"
            method="post"
            role="form"
            onSubmit={this.submitForm}
            action="https://formspree.io/f/mknpkdey"
          >
            <CardBody>
              <Row>
                <Col md="6">
                  <label>First name</label>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": this.firstNameFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      aria-label="First Name..."
                      placeholder="First Name..."
                      type="text"
                      name="firstName"
                      onFocus={(e) =>
                        this.setState({
                          firstNameFocus: (this.firstNameFocus = true),
                        })
                      }
                      onBlur={(e) =>
                        this.setState({
                          firstNameFocus: (this.firstNameFocus = false),
                        })
                      }
                    />
                  </InputGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label>Last name</label>
                    <InputGroup
                      className={classnames({
                        "input-group-focus": this.lastNameFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-caps-small" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        aria-label="Last Name..."
                        placeholder="Last Name..."
                        type="text"
                        name="lastName"
                        onFocus={(e) =>
                          this.setState({
                            lastNameFocus: (this.lastNameFocus = true),
                          })
                        }
                        onBlur={(e) =>
                          this.setState({
                            lastNameFocus: (this.lastNameFocus = false),
                          })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <label>Email address</label>
                <InputGroup
                  className={classnames({
                    "input-group-focus": this.emailFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="tim-icons icon-email-85" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email Here..."
                    type="text"
                    name="email"
                    onFocus={(e) =>
                      this.setState({ emailFocus: (this.emailFocus = true) })
                    }
                    onBlur={(e) =>
                      this.setState({ emailFocus: (this.emailFocus = false) })
                    }
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <label>Your message</label>
                <Input id="message-1" name="message" rows="6" type="textarea" />
              </FormGroup>
              <Row>
                <Col className="ml-auto" md="6">
                  <Button className="btn-round pull-right" color="primary">
                    Send Message
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Form>
        </Col>
      </Row>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
