import React, { useState } from "react";
import { useForm } from "react-hook-form";
// nodejs library that concatenates classes
import classnames from "classnames";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Alert,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  CustomInput,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import VsstaHomeNavbar from "components/Navbars/VsstaHomeNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import TagsInput from "components/TagsInput/TagsInput.js";

import axios from "../../utils/Axios";

export default function Leads() {
  const { register, handleSubmit, errors } = useForm();
  const [email, setEmail] = useState("");

  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("account-settings");
    return function cleanup() {
      document.body.classList.remove("account-settings");
    };
  }, []);

  function onSubmit(data) {
    console.log(data);
    axios
      .post("new_lead", data)
      .then((res) => {
        console.log(res);

        alert("Lead Ticket Has Been Submitted");
        document.getElementById("form").reset();
      })
      .catch((error) => {
        console.log(error);
        document.getElementById("form").reset();
      });
  }
  return (
    <>
      <VsstaHomeNavbar />
      <div className="wrapper" ref={wrapper}>
        <div style={{ margin: "150px" }}></div>
        <div className="section">
          <Container>
            <Form onSubmit={handleSubmit(onSubmit)} id="form">
              <Row style={{ paddingLeft: "100px", paddingRight: "100px" }}>
                <Col className="ml-auto" md="12">
                  <div className="section">
                    <header>
                      <h2 className="text-uppercase">Partner information</h2>
                    </header>
                    <hr className="line-info" />
                    <br />
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#username">
                          Partner Company Name
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="Partner Company Name"
                            id="partner_company_name"
                            name="partner_company_name"
                            required={true}
                            type="text"
                          />
                          {errors.partner_company_name && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#first_name">
                          First Name
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="Charlie"
                            id="first_name"
                            name="first_name"
                            required={true}
                            type="text"
                          />
                          {errors.first_name && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#last_name">
                          Last Name
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="Bailey"
                            id="last_name"
                            name="last_name"
                            required={true}
                            type="text"
                          />
                          {errors.last_name && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#email">
                          Business Email
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="charlie.bailey@example.com"
                            id="email"
                            name="email"
                            required={true}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                          />
                          {errors.email && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <p style={{ margin: "100px" }}></p>
                    <header>
                      <h2 className="text-uppercase">
                        Referred Company information
                      </h2>
                    </header>
                    <hr className="line-info" />
                    <br />
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#shop_name">
                          Company Name
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="Mikes Collision Center"
                            id="company_name"
                            name="company_name"
                            required={true}
                            type="text"
                          />
                          {errors.company_name && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#shop_name">
                          Contact Person
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="Bill Wilson"
                            id="contact_person"
                            name="contact_person"
                            required={true}
                            type="text"
                          />
                          {errors.contact_person && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#shop_name">
                          Contact Phone
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="1-800-177-2334"
                            id="contact_phone"
                            name="contact_phone"
                            required={true}
                            type="text"
                          />
                          {errors.contact_person && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#shop_name">
                          Contact Email
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="charlie.bailey@example.com"
                            id="contact_email"
                            name="contact_email"
                            required={true}
                            type="text"
                          />
                          {errors.contact_email && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#street">
                          Address
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="455 South Main Street"
                            id="address"
                            name="address"
                            required={true}
                            type="text"
                          />
                          {errors["address"] && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#city">
                          City
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="Houston"
                            id="city"
                            name="city"
                            required={true}
                            type="text"
                          />
                          {errors.city && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#state">
                          State
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="Texas"
                            id="state"
                            name="state"
                            required={true}
                            type="text"
                          />
                          {errors.state && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#post_code">
                          Post Code
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="43318"
                            id="post_code"
                            name="post_code"
                            required={true}
                            type="text"
                          />
                          {errors.post_code && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#country">
                          Country
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="United States"
                            id="country"
                            name="country"
                            required={true}
                            type="text"
                          />
                          {errors.country && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#country">
                          Monthly Car Count
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="300"
                            id="car_count"
                            name="car_count"
                            required={true}
                            type="text"
                          />
                          {errors.car_count && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels">Type of Lead</label>
                      </Col>
                      <Col className="align-self-center" md="4">
                        <FormGroup>
                          <Input
                            type="select"
                            innerRef={register({ required: true })}
                            id="leadType"
                            name="leadType"
                            required={true}
                            className="react-select react-select-info"
                            classNamePrefix="react-select"
                            placeholder="Type of Lead"
                          >
                            <option value="Vssta Scanning">
                              Vssta Scanning
                            </option>
                            <option value="Equipment">Equipment</option>
                            <option value="Vssta Scanning + Equipment">
                              Vssta Scanning + Equipment
                            </option>
                          </Input>
                          {errors.leadType && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels">Current Vendor</label>
                      </Col>
                      <Col className="align-self-center" md="4">
                        <FormGroup>
                          <Input
                            type="select"
                            innerRef={register({ required: true })}
                            id="currentVendor"
                            name="currentVendor"
                            required={true}
                            className="react-select react-select-info"
                            classNamePrefix="react-select"
                            placeholder="Current Vendor"
                          >
                            <option value="None">None</option>
                            <option value="Astech">Astech</option>
                            <option value="Airpro">Airpro</option>
                            <option value="Autel">Autel</option>
                            <option value="Snap On">Snap On</option>
                            <option value="DrewTech">DrewTech</option>
                            <option value="Other">Other</option>
                          </Input>
                          {errors.currentVendor && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="3">
                        <label className="labels" htmlFor="#country">
                          Any notes for opportunity
                        </label>
                      </Col>
                      <Col className="align-self-center" md="9">
                        <FormGroup>
                          <Input
                            innerRef={register({ required: true })}
                            placeholder="Shop could use help ....."
                            id="notes"
                            name="notes"
                            required={true}
                            type="textarea"
                          />
                          {errors.notes && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="align-self-center" md="12">
                        <FormGroup style={{ paddingLeft: "20px" }}>
                          <Input
                            type="checkbox"
                            innerRef={register({ required: true })}
                            id="terms"
                            name="terms"
                            required={true}
                            className="react-select react-select-info"
                            classNamePrefix="react-select"
                            placeholder="Terms and Conditions"
                          ></Input>
                          <label for="terms" className="labels">
                            Terms: By submitting this form, you agree to the
                            VSSTA Vehicle Scanning Solutions, LLC Partner
                            Program Terms as applicable based on your program
                            eligibility and your submitted request. Leads are
                            valid for 30 days. It is the sole responsibility of
                            the partner to resubmit lead if still working after
                            30 days. Submitting lead form is not a confirmation
                            of referral. Lead will be evaluated and VSSTA will
                            confirm lead has not already been submitted by
                            another partner. You will receive confirmation of
                            valid lead.
                          </label>

                          {errors.terms && (
                            <Alert color="danger">This field is required</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col md="6">
                        <Button
                          color="info"
                          type="submit"
                          onClick={handleSubmit(onSubmit)}
                        >
                          Send
                        </Button>
                        <Button
                          className="btn-simple ml-1"
                          color="info"
                          type="button"
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </div>
      <div style={{ margin: "250px" }}></div>
    </>
  );
}
