import React from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import VSSTA_LOGO from "assets/img/vssta_logo.png";
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

export default function VsstaHomeNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  React.useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);
  const changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.body.scrollTop > 50
    ) {
      setNavbarColor("bg-info");
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      setNavbarColor("navbar-transparent");
    }
  };
  return (
    <>
      <Navbar  className={"fixed-top " + navbarColor} expand="lg">
        <Container className='container1300'>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="tooltip6619950104">
              <img
                className="img-center img-fluid"
                alt="vssta Logo" 
                src={VSSTA_LOGO}
                height="110px"
                width="180px" 
              />
            </NavbarBrand>
            <UncontrolledTooltip delay={0} target="tooltip6619950104">
            </UncontrolledTooltip>
            <button className="navbar-toggler" id="navigation">
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <UncontrolledCollapse navbar toggler="#navigation">
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    VSSTA• <span>Vehicle Scanning Solutions</span>
                  </a>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button className="navbar-toggler" id="navigation">
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link className="btn btn-link text-success" to="/">
                  Home
                </Link>
                <Link className="btn btn-link text-success" to="/contact-us">
                  Contact
                </Link>
                <Link className="btn btn-link text-success" to="/support">
                  Tech Support
                </Link>
                <Link className="btn btn-link text-success" to="/adas_certification">
                  Adas Certification
                </Link>
                <Link className="btn btn-link text-success" to="/leads">
                  Leads
                </Link>
                <Link className="btn btn-link text-success" to="/signup-ticket">
                  Sign Up Ticket
                </Link>
                <Button
                  className="btn-round"
                  color="info"
                  href="http://portal.vssta.com/auth/login/?next=/"
                >
                  Login to Portal
                </Button>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
}
